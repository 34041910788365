"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartListParams = void 0;
var ChartListParams = /** @class */ (function () {
    function ChartListParams() {
    }
    ChartListParams.prototype.withFilter = function (filter) {
        this.filter = filter;
        return this;
    };
    ChartListParams.prototype.withTag = function (tag) {
        this.tag = tag;
        return this;
    };
    ChartListParams.prototype.withExpandEvents = function (expandEvents) {
        if (expandEvents) {
            this.expand = 'events';
        }
        return this;
    };
    ChartListParams.prototype.withEventsLimit = function (eventsLimit) {
        this.eventsLimit = eventsLimit;
        return this;
    };
    ChartListParams.prototype.withValidation = function (validation) {
        this.validation = validation;
        return this;
    };
    ChartListParams.prototype.serialize = function () {
        return {
            tag: this.tag,
            expand: this.expand,
            filter: this.filter,
            validation: this.validation,
            eventsLimit: this.eventsLimit
        };
    };
    return ChartListParams;
}());
exports.ChartListParams = ChartListParams;
