"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Channels = void 0;
var Channels = /** @class */ (function () {
    function Channels(client) {
        this.client = client;
    }
    Channels.prototype.add = function (eventKey, channelKey, name, color, index, objects) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/channels"), {
            key: channelKey,
            name: name,
            color: color,
            index: index,
            objects: objects
        });
    };
    Channels.prototype.addMultiple = function (eventKey, channelCreationParams) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/channels"), channelCreationParams);
    };
    Channels.prototype.remove = function (eventKey, channelKey) {
        return this.client.delete("/events/".concat(encodeURIComponent(eventKey), "/channels/").concat(encodeURIComponent(channelKey)));
    };
    Channels.prototype.update = function (eventKey, channelKey, newChannelName, newColor, newObjects) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/channels/").concat(encodeURIComponent(channelKey)), {
            name: newChannelName,
            color: newColor,
            objects: newObjects
        });
    };
    Channels.prototype.addObjects = function (eventKey, channelKey, objects) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/channels/").concat(encodeURIComponent(channelKey), "/objects"), {
            objects: objects
        });
    };
    Channels.prototype.removeObjects = function (eventKey, channelKey, objects) {
        return this.client.delete("/events/".concat(encodeURIComponent(eventKey), "/channels/").concat(encodeURIComponent(channelKey), "/objects"), {
            data: { objects: objects }
        });
    };
    Channels.prototype.replace = function (eventKey, channels) {
        return this.client.post("/events/".concat(encodeURIComponent(eventKey), "/channels/replace"), { channels: channels });
    };
    return Channels;
}());
exports.Channels = Channels;
