"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractEventParams = void 0;
var AbstractEventParams = /** @class */ (function () {
    function AbstractEventParams() {
    }
    AbstractEventParams.prototype.withKey = function (key) {
        this.key = key;
        return this;
    };
    AbstractEventParams.prototype.withTableBookingConfig = function (tableBookingConfig) {
        this.tableBookingConfig = tableBookingConfig;
        return this;
    };
    AbstractEventParams.prototype.withObjectCategories = function (objectCategories) {
        this.objectCategories = objectCategories;
        return this;
    };
    AbstractEventParams.prototype.withCategories = function (categories) {
        this.categories = categories;
        return this;
    };
    AbstractEventParams.prototype.withName = function (name) {
        this.name = name;
        return this;
    };
    AbstractEventParams.prototype.withDate = function (date) {
        this.date = date;
        return this;
    };
    return AbstractEventParams;
}());
exports.AbstractEventParams = AbstractEventParams;
