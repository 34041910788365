"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Labels = void 0;
var Labels = /** @class */ (function () {
    function Labels(own, parent) {
        this.own = own;
        this.parent = parent || null;
    }
    Labels.prototype.setSection = function (value) {
        this.section = value;
    };
    Labels.prototype.getSection = function () {
        return this.section;
    };
    return Labels;
}());
exports.Labels = Labels;
