"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Charts = void 0;
var Lister_1 = require("../Lister");
var Page_1 = require("../Page");
var Chart_1 = require("./Chart");
var Charts = /** @class */ (function () {
    function Charts(client) {
        this.client = client;
        this.archive = new Lister_1.Lister('/charts/archive', this.client, 'charts', function (data) {
            var charts = data.items.map(function (chartData) { return new Chart_1.Chart(chartData); });
            return new Page_1.Page(charts, data.next_page_starts_after, data.previous_page_ends_before);
        });
    }
    Charts.prototype.create = function (name, venueType, categories) {
        if (name === void 0) { name = null; }
        if (venueType === void 0) { venueType = null; }
        if (categories === void 0) { categories = null; }
        var requestParameters = {};
        if (name !== null) {
            requestParameters.name = name;
        }
        if (venueType !== null) {
            requestParameters.venueType = venueType;
        }
        if (categories !== null) {
            requestParameters.categories = categories;
        }
        return this.client.post('charts', requestParameters)
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.update = function (key, name, categories) {
        if (name === void 0) { name = null; }
        if (categories === void 0) { categories = null; }
        var requestParameters = {};
        if (name !== null) {
            requestParameters.name = name;
        }
        if (categories !== null) {
            requestParameters.categories = categories;
        }
        return this.client.post("/charts/".concat(key), requestParameters);
    };
    Charts.prototype.addCategory = function (key, category) {
        return this.client.post("/charts/".concat(key, "/categories"), category);
    };
    Charts.prototype.removeCategory = function (chartKey, categoryKey) {
        return this.client.delete("/charts/".concat(chartKey, "/categories/").concat(categoryKey));
    };
    Charts.prototype.listCategories = function (key) {
        return this.client.get("/charts/".concat(key, "/categories"))
            .then(function (res) { return res.data.categories; });
    };
    Charts.prototype.validatePublishedVersion = function (key) {
        return this.client.post("/charts/".concat(key, "/version/published/actions/validate"))
            .then(function (res) { return res.data; });
    };
    Charts.prototype.validateDraftVersion = function (key) {
        return this.client.post("/charts/".concat(key, "/version/draft/actions/validate"))
            .then(function (res) { return res.data; });
    };
    Charts.prototype.retrieve = function (key) {
        return this.client.get("charts/".concat(key))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.retrieveWithEvents = function (key) {
        return this.client.get("charts/".concat(key, "?expand=events"))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.retrievePublishedVersion = function (key) {
        return this.client.get("charts/".concat(key, "/version/published"))
            .then(function (res) { return res.data; });
    };
    Charts.prototype.retrieveDraftVersion = function (key) {
        return this.client.get("charts/".concat(key, "/version/draft"))
            .then(function (res) { return res.data; });
    };
    Charts.prototype.publishDraftVersion = function (key) {
        return this.client.post("charts/".concat(key, "/version/draft/actions/publish"));
    };
    Charts.prototype.discardDraftVersion = function (key) {
        return this.client.post("/charts/".concat(key, "/version/draft/actions/discard"));
    };
    Charts.prototype.moveToArchive = function (key) {
        return this.client.post("charts/".concat(key, "/actions/move-to-archive"));
    };
    Charts.prototype.moveOutOfArchive = function (key) {
        return this.client.post("charts/".concat(key, "/actions/move-out-of-archive"));
    };
    Charts.prototype.copy = function (key) {
        return this.client.post("charts/".concat(key, "/version/published/actions/copy"))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.copyDraftVersion = function (key) {
        return this.client.post("charts/".concat(key, "/version/draft/actions/copy"))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.copyToWorkspace = function (key, workspaceKey) {
        return this.client.post("charts/".concat(key, "/version/published/actions/copy-to-workspace/").concat(workspaceKey))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.copyFromWorkspaceTo = function (key, fromWorkspaceKey, toWorkspaceKey) {
        return this.client.post("charts/".concat(key, "/version/published/actions/copy/from/").concat(fromWorkspaceKey, "/to/").concat(toWorkspaceKey))
            .then(function (res) { return new Chart_1.Chart(res.data); });
    };
    Charts.prototype.retrievePublishedVersionThumbnail = function (key) {
        return this.client.get("/charts/".concat(key, "/version/published/thumbnail"), { responseType: 'arraybuffer' })
            .then(function (res) { return res.data; });
    };
    Charts.prototype.retrieveDraftVersionThumbnail = function (key) {
        return this.client.get("/charts/".concat(key, "/version/draft/thumbnail"), { responseType: 'arraybuffer' })
            .then(function (res) { return res.data; });
    };
    Charts.prototype.listAllTags = function () {
        return this.client.get('/charts/tags')
            .then(function (res) { return res.data.tags; });
    };
    Charts.prototype.addTag = function (key, tag) {
        var url = "charts/".concat(key, "/tags/").concat(encodeURIComponent(tag));
        return this.client.post(url);
    };
    Charts.prototype.removeTag = function (key, tag) {
        var url = "charts/".concat(key, "/tags/").concat(encodeURIComponent(tag));
        return this.client.delete(url);
    };
    Charts.prototype.listAll = function (chartListParams) {
        if (chartListParams === void 0) { chartListParams = null; }
        return this.iterator().all(chartListParams);
    };
    Charts.prototype.listFirstPage = function (chartListParams, pageSize) {
        if (chartListParams === void 0) { chartListParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().firstPage(chartListParams, pageSize);
    };
    Charts.prototype.listPageAfter = function (afterId, chartListParams, pageSize) {
        if (chartListParams === void 0) { chartListParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageAfter(afterId, chartListParams, pageSize);
    };
    Charts.prototype.listPageBefore = function (beforeId, chartListParams, pageSize) {
        if (chartListParams === void 0) { chartListParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.iterator().pageBefore(beforeId, chartListParams, pageSize);
    };
    Charts.prototype.iterator = function () {
        return new Lister_1.Lister('/charts', this.client, 'charts', function (data) {
            var charts = data.items.map(function (chartData) { return new Chart_1.Chart(chartData); });
            return new Page_1.Page(charts, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    return Charts;
}());
exports.Charts = Charts;
