"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LabelAndType = void 0;
var LabelAndType = /** @class */ (function () {
    function LabelAndType(label, type) {
        this.label = label;
        this.type = type;
    }
    return LabelAndType;
}());
exports.LabelAndType = LabelAndType;
