"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForSaleConfig = void 0;
var ForSaleConfig = /** @class */ (function () {
    function ForSaleConfig(forSale, objects, areaPlaces, categories) {
        this.forSale = forSale;
        this.areaPlaces = areaPlaces;
        this.objects = objects;
        this.categories = categories;
    }
    return ForSaleConfig;
}());
exports.ForSaleConfig = ForSaleConfig;
