"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
var ForSaleConfig_1 = require("./ForSaleConfig");
var Channel_1 = require("./Channel");
var Category_1 = require("../Charts/Category");
var LocalDate_1 = require("../LocalDate");
var Event = /** @class */ (function () {
    function Event(json) {
        this.id = json.id;
        this.key = json.key;
        this.tableBookingConfig = json.tableBookingConfig;
        this.supportsBestAvailable = json.supportsBestAvailable;
        this.forSaleConfig = json.forSaleConfig ? new ForSaleConfig_1.ForSaleConfig(json.forSaleConfig.forSale, json.forSaleConfig.objects, json.forSaleConfig.areaPlaces, json.forSaleConfig.categories) : null;
        this.chartKey = json.chartKey;
        this.createdOn = json.createdOn ? new Date(json.createdOn) : null;
        this.updatedOn = json.updatedOn ? new Date(json.updatedOn) : null;
        this.channels = json.channels ? json.channels.map(function (c) { return new Channel_1.Channel(c); }) : null;
        this.topLevelSeasonKey = json.topLevelSeasonKey;
        this.isTopLevelSeason = json.isTopLevelSeason;
        this.isPartialSeason = json.isPartialSeason;
        this.isEventInSeason = json.isEventInSeason;
        this.objectCategories = json.objectCategories;
        this.categories = json.categories ? json.categories.map(function (c) { return Category_1.Category.fromJson(c); }) : null;
        this.name = json.name || null;
        this.date = json.date ? LocalDate_1.LocalDate.parse(json.date) : null;
        this.isInThePast = json.isInThePast;
        this.partialSeasonKeysForEvent = json.partialSeasonKeysForEvent;
    }
    Event.prototype.isSeason = function () {
        return false;
    };
    return Event;
}());
exports.Event = Event;
