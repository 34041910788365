"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
var Page = /** @class */ (function () {
    function Page(items, afterId, beforeId) {
        if (afterId === void 0) { afterId = null; }
        if (beforeId === void 0) { beforeId = null; }
        this.items = items;
        this.nextPageStartsAfter = afterId;
        this.previousPageEndsBefore = beforeId;
    }
    Page.prototype[Symbol.iterator] = function () {
        var index = 0;
        var charts = this.items;
        return {
            next: function () {
                if (index < charts.length) {
                    var chart = charts[index];
                    index++;
                    return { value: chart, done: false };
                }
                else {
                    return { done: true };
                }
            }
        };
    };
    return Page;
}());
exports.Page = Page;
