"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectProperties = void 0;
var ObjectProperties = /** @class */ (function () {
    function ObjectProperties(objectId) {
        this.objectId = objectId;
    }
    ObjectProperties.prototype.setTicketType = function (ticketType) {
        this.ticketType = ticketType;
        return this;
    };
    ObjectProperties.prototype.setQuantity = function (quantity) {
        this.quantity = quantity;
        return this;
    };
    ObjectProperties.prototype.setExtraData = function (extraData) {
        this.extraData = extraData;
        return this;
    };
    return ObjectProperties;
}());
exports.ObjectProperties = ObjectProperties;
