"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HoldTokens = void 0;
var HoldToken_1 = require("./HoldToken");
var HoldTokens = /** @class */ (function () {
    function HoldTokens(client) {
        this.client = client;
    }
    HoldTokens.prototype.create = function (expiresInMinutes) {
        if (expiresInMinutes === void 0) { expiresInMinutes = null; }
        var request = {};
        if (expiresInMinutes !== null) {
            request.expiresInMinutes = expiresInMinutes;
        }
        return this.client.post('/hold-tokens', request).then(function (res) { return new HoldToken_1.HoldToken(res.data); });
    };
    HoldTokens.prototype.expiresInMinutes = function (holdToken, minutes) {
        var request = {};
        request.expiresInMinutes = minutes;
        return this.client.post("/hold-tokens/".concat(holdToken), request).then(function (res) { return new HoldToken_1.HoldToken(res.data); });
    };
    HoldTokens.prototype.retrieve = function (holdToken) {
        return this.client.get("/hold-tokens/".concat(holdToken)).then(function (res) { return new HoldToken_1.HoldToken(res.data); });
    };
    return HoldTokens;
}());
exports.HoldTokens = HoldTokens;
