"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BestAvailableObjects = void 0;
var reportUtility_1 = require("../utilities/reportUtility");
var BestAvailableObjects = /** @class */ (function () {
    function BestAvailableObjects(json) {
        this.objects = json.objects;
        this.objectDetails = reportUtility_1.Utilities.createChangeObjectStatusDetails(json.objectDetails);
        this.nextToEachOther = json.nextToEachOther;
    }
    return BestAvailableObjects;
}());
exports.BestAvailableObjects = BestAvailableObjects;
