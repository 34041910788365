"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeObjectStatusResult = void 0;
var reportUtility_1 = require("../utilities/reportUtility");
var ChangeObjectStatusResult = /** @class */ (function () {
    function ChangeObjectStatusResult(objects) {
        this.objects = reportUtility_1.Utilities.createChangeObjectStatusDetails(objects);
    }
    return ChangeObjectStatusResult;
}());
exports.ChangeObjectStatusResult = ChangeObjectStatusResult;
