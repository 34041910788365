"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartReports = exports.Versions = void 0;
var reportUtility_1 = require("../utilities/reportUtility");
var Versions = /** @class */ (function () {
    function Versions() {
    }
    Versions.publishedVersion = 'published';
    Versions.draftVersion = 'draft';
    return Versions;
}());
exports.Versions = Versions;
var ChartReports = /** @class */ (function () {
    function ChartReports(client) {
        this.client = client;
    }
    ChartReports.prototype.byLabel = function (chartKey, bookWholeTables, version) {
        return this.fetchReport('byLabel', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.byObjectType = function (chartKey, bookWholeTables, version) {
        return this.fetchReport('byObjectType', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.summaryByObjectType = function (chartKey, bookWholeTables, version) {
        return this.fetchSummaryReport('byObjectType', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.byCategoryLabel = function (chartKey, bookWholeTables, version) {
        return this.fetchReport('byCategoryLabel', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.summaryByCategoryLabel = function (chartKey, bookWholeTables, version) {
        return this.fetchSummaryReport('byCategoryLabel', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.byCategoryKey = function (chartKey, bookWholeTables, version) {
        return this.fetchReport('byCategoryKey', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.summaryByCategoryKey = function (chartKey, bookWholeTables, version) {
        return this.fetchSummaryReport('byCategoryKey', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.bySection = function (chartKey, bookWholeTables, version) {
        return this.fetchReport('bySection', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.summaryBySection = function (chartKey, bookWholeTables, version) {
        return this.fetchSummaryReport('bySection', chartKey, bookWholeTables, version);
    };
    ChartReports.prototype.fetchReport = function (reportType, chartKey, bookWholeTables, version) {
        return this.client.get("/reports/charts/".concat(encodeURIComponent(chartKey), "/").concat(reportType), { params: { bookWholeTables: bookWholeTables, version: version } })
            .then(function (res) { return reportUtility_1.Utilities.createChartReport(res.data); });
    };
    ChartReports.prototype.fetchSummaryReport = function (reportType, chartKey, bookWholeTables, version) {
        return this.client.get("/reports/charts/".concat(encodeURIComponent(chartKey), "/").concat(reportType, "/summary"), { params: { bookWholeTables: bookWholeTables, version: version } })
            .then(function (res) { return res.data; });
    };
    return ChartReports;
}());
exports.ChartReports = ChartReports;
