"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Seasons = void 0;
var Season_1 = require("./Season");
var EventDeserializer_1 = require("../Events/EventDeserializer");
var Seasons = /** @class */ (function () {
    function Seasons(client, seatsioClient) {
        this.client = client;
        this.seatsioClient = seatsioClient;
    }
    Seasons.prototype.create = function (chartKey, seasonParams) {
        if (seasonParams === void 0) { seasonParams = null; }
        var requestParameters = {};
        requestParameters.chartKey = chartKey;
        if (seasonParams !== null) {
            if (seasonParams._key !== undefined) {
                requestParameters.key = seasonParams._key;
            }
            if (seasonParams._numberOfEvents !== undefined) {
                requestParameters.numberOfEvents = seasonParams._numberOfEvents;
            }
            if (seasonParams._eventKeys !== undefined) {
                requestParameters.eventKeys = seasonParams._eventKeys;
            }
            if (seasonParams._tableBookingConfig !== undefined) {
                requestParameters.tableBookingConfig = seasonParams._tableBookingConfig;
            }
            if (seasonParams._channels !== undefined) {
                requestParameters.channels = seasonParams._channels;
            }
            if (seasonParams._forSaleConfig !== undefined) {
                requestParameters.forSaleConfig = seasonParams._forSaleConfig;
            }
        }
        return this.client.post('/seasons', requestParameters)
            .then(function (res) { return new Season_1.Season(res.data); });
    };
    Seasons.prototype.createPartialSeason = function (topLevelSeasonKey, partialSeasonKey, eventKeys) {
        if (partialSeasonKey === void 0) { partialSeasonKey = null; }
        if (eventKeys === void 0) { eventKeys = null; }
        var requestParameters = {};
        if (partialSeasonKey !== null) {
            requestParameters.key = partialSeasonKey;
        }
        if (eventKeys !== null) {
            requestParameters.eventKeys = eventKeys;
        }
        return this.client.post("/seasons/".concat(encodeURIComponent(topLevelSeasonKey), "/partial-seasons"), requestParameters)
            .then(function (res) { return new Season_1.Season(res.data); });
    };
    Seasons.prototype.createEvents = function (key, numberOfEvents, eventKeys) {
        if (numberOfEvents === void 0) { numberOfEvents = null; }
        if (eventKeys === void 0) { eventKeys = null; }
        var requestParameters = {};
        if (numberOfEvents !== null) {
            requestParameters.numberOfEvents = numberOfEvents;
        }
        if (eventKeys !== null) {
            requestParameters.eventKeys = eventKeys;
        }
        return this.client.post("/seasons/".concat(encodeURIComponent(key), "/actions/create-events"), requestParameters)
            .then(function (res) { return res.data.events.map(function (e) { return new EventDeserializer_1.EventDeserializer().fromJson(e); }); });
    };
    Seasons.prototype.addEventsToPartialSeason = function (topLevelSeasonKey, partialSeasonKey, eventKeys) {
        var requestParameters = { eventKeys: eventKeys };
        return this.client.post("/seasons/".concat(encodeURIComponent(topLevelSeasonKey), "/partial-seasons/").concat(encodeURIComponent(partialSeasonKey), "/actions/add-events"), requestParameters)
            .then(function (res) { return new Season_1.Season(res.data); });
    };
    Seasons.prototype.removeEventFromPartialSeason = function (topLevelSeasonKey, partialSeasonKey, eventKey) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.client.delete("/seasons/".concat(encodeURIComponent(topLevelSeasonKey), "/partial-seasons/").concat(encodeURIComponent(partialSeasonKey), "/events/").concat(encodeURIComponent(eventKey)))
                        .then(function (res) { return new Season_1.Season(res.data); })];
            });
        });
    };
    Seasons.prototype.retrieve = function (key) {
        return this.seatsioClient.events.retrieve(key);
    };
    return Seasons;
}());
exports.Seasons = Seasons;
