"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Region = void 0;
var Region = /** @class */ (function () {
    function Region(url) {
        this.url = url;
    }
    Region.EU = function () {
        return new Region(Region.urlForId('eu'));
    };
    Region.NA = function () {
        return new Region(Region.urlForId('na'));
    };
    Region.SA = function () {
        return new Region(Region.urlForId('sa'));
    };
    Region.OC = function () {
        return new Region(Region.urlForId('oc'));
    };
    Region.urlForId = function (id) {
        return 'https://api-{region}.seatsio.net'.replace('{region}', id);
    };
    return Region;
}());
exports.Region = Region;
