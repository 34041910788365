"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventDeserializer = void 0;
var Season_1 = require("../Seasons/Season");
var Event_1 = require("./Event");
var EventDeserializer = /** @class */ (function () {
    function EventDeserializer() {
    }
    EventDeserializer.prototype.fromJson = function (json) {
        if (json.isSeason) {
            return new Season_1.Season(json);
        }
        return new Event_1.Event(json);
    };
    return EventDeserializer;
}());
exports.EventDeserializer = EventDeserializer;
