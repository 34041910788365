"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Workspace = void 0;
var Workspace = /** @class */ (function () {
    function Workspace(json) {
        this.id = json.id;
        this.key = json.key;
        this.secretKey = json.secretKey;
        this.name = json.name;
        this.settings = json.settings;
        this.isDefault = json.isDefault;
        this.isTest = json.isTest;
        this.isActive = json.isActive;
    }
    return Workspace;
}());
exports.Workspace = Workspace;
