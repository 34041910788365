"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusChangesParams = void 0;
var StatusChangesParams = /** @class */ (function () {
    function StatusChangesParams(filter, filterType) {
        if (filterType === void 0) { filterType = 'CONTAINS'; }
        this.filter = filter;
        this.filterType = filterType;
    }
    StatusChangesParams.prototype.sortByObjectLabel = function () {
        this.sortField = 'objectLabel';
        return this;
    };
    StatusChangesParams.prototype.sortByStatus = function () {
        this.sortField = 'status';
        return this;
    };
    StatusChangesParams.prototype.sortByDate = function () {
        this.sortField = 'date';
        return this;
    };
    StatusChangesParams.prototype.sortAscending = function () {
        this.sortDirection = 'asc';
        return this;
    };
    StatusChangesParams.prototype.sortDescending = function () {
        this.sortDirection = 'desc';
        return this;
    };
    StatusChangesParams.prototype.withFilter = function (filter, filterType) {
        if (filterType === void 0) { filterType = 'CONTAINS'; }
        this.filter = filter;
        this.filterType = filterType;
        return this;
    };
    StatusChangesParams.prototype.serialize = function () {
        var sort = null;
        if (this.sortField && this.sortDirection) {
            sort = this.sortField + ':' + this.sortDirection;
        }
        else if (!this.sortField && this.sortDirection) {
            sort = 'date:' + this.sortDirection;
        }
        else if (this.sortField && !this.sortDirection) {
            sort = this.sortField + ':asc';
        }
        return {
            filter: this.filter,
            filterType: this.filterType,
            sort: sort
        };
    };
    return StatusChangesParams;
}());
exports.StatusChangesParams = StatusChangesParams;
