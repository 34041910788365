import { Region, SeatsioClient } from 'seatsio';

const setup = (subscribe, send) => {
    const fetchSeatsIOEventReport = async (seatsIoEvent) => {
        console.log('seatsIoEvent', seatsIoEvent);
        if (seatsIoEvent.workspaceSecret !== null) {
            let client = new SeatsioClient(Region.EU(), seatsIoEvent.workspaceSecret);

            const result = await client.eventReports.summaryByAvailability(seatsIoEvent.key);

            const availability = {
                not_available: result.not_available.count,
                available: result.available.count,
                key: seatsIoEvent.key,
            };

            send(availability);
        }
    };

    subscribe(fetchSeatsIOEventReport);
};

const seatsIoEventSummary = { setup: setup };

export default seatsIoEventSummary;
