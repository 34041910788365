"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HoldToken = void 0;
var HoldToken = /** @class */ (function () {
    function HoldToken(json) {
        this.holdToken = json.holdToken;
        this.expiresAt = new Date(json.expiresAt);
        this.expiresInSeconds = json.expiresInSeconds;
        this.workspaceKey = json.workspaceKey;
    }
    return HoldToken;
}());
exports.HoldToken = HoldToken;
