"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountSettings = void 0;
var ChartValidationSettings_1 = require("./ChartValidationSettings");
var DefaultRendererSettings_1 = require("./DefaultRendererSettings");
var AccountSettings = /** @class */ (function () {
    function AccountSettings(settings) {
        this.draftChartDrawingsEnabled = settings.draftChartDrawingsEnabled;
        this.holdOnSelectForGAs = settings.holdOnSelectForGAs;
        this.holdPeriodInMinutes = settings.holdPeriodInMinutes;
        this.chartValidation = new ChartValidationSettings_1.ChartValidationSettings(settings.chartValidation);
        this.defaultRendererSettings = new DefaultRendererSettings_1.DefaultRendererSettings(settings.defaultRendererSettings);
    }
    return AccountSettings;
}());
exports.AccountSettings = AccountSettings;
