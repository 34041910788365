"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Users = void 0;
var Invitation_1 = require("../Invitations/Invitation");
var Page_1 = require("../Page");
var User_1 = require("./User");
var Lister_1 = require("../Lister");
var Users = /** @class */ (function () {
    function Users(client) {
        this.client = client;
    }
    Users.prototype.invite = function (email, role, workspaces) {
        var requestParameters = { email: email, role: role, workspaces: workspaces };
        return this.client.post('/users/actions/invite', requestParameters)
            .then(function (res) { return new Invitation_1.Invitation(res.data); });
    };
    Users.prototype.retrieve = function (id) {
        return this.client.get("/users/".concat(id)).then(function (res) { return new User_1.User(res.data); });
    };
    Users.prototype.activate = function (id) {
        return this.client.post("/users/".concat(id, "/actions/activate"));
    };
    Users.prototype.deactivate = function (id) {
        return this.client.post("/users/".concat(id, "/actions/deactivate"));
    };
    Users.prototype.listAll = function (role) {
        if (role === void 0) { role = null; }
        var requestParameters = role !== null ? { role: role } : {};
        return this.iterator().all(requestParameters);
    };
    Users.prototype.listFirstPage = function (role, pageSize) {
        if (role === void 0) { role = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = role !== null ? { role: role } : {};
        return this.iterator().firstPage(requestParameters, pageSize);
    };
    Users.prototype.listPageAfter = function (afterId, role, pageSize) {
        if (role === void 0) { role = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = role !== null ? { role: role } : {};
        return this.iterator().pageAfter(afterId, requestParameters, pageSize);
    };
    Users.prototype.listPageBefore = function (beforeId, role, pageSize) {
        if (role === void 0) { role = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = role !== null ? { role: role } : {};
        return this.iterator().pageBefore(beforeId, requestParameters, pageSize);
    };
    Users.prototype.iterator = function () {
        return new Lister_1.Lister('/users', this.client, 'users', function (data) {
            var users = data.items.map(function (usersData) { return new User_1.User(usersData); });
            return new Page_1.Page(users, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    return Users;
}());
exports.Users = Users;
