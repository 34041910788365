"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartValidation = void 0;
var ChartValidation = /** @class */ (function () {
    function ChartValidation(json) {
        this.errors = json.errors;
        this.warnings = json.warnings;
    }
    return ChartValidation;
}());
exports.ChartValidation = ChartValidation;
