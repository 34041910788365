"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Category = void 0;
var Category = /** @class */ (function () {
    function Category(key, label, color, accessible) {
        this.key = key;
        this.label = label;
        this.color = color;
        this.accessible = accessible;
    }
    Category.prototype.setKey = function (key) {
        this.key = key;
        return this;
    };
    Category.prototype.setLabel = function (label) {
        this.label = label;
        return this;
    };
    Category.prototype.setColor = function (color) {
        this.color = color;
        return this;
    };
    Category.prototype.setAccessible = function (accessible) {
        this.accessible = accessible;
        return this;
    };
    Category.fromJson = function (json) {
        return new Category(json.key, json.label, json.color, json.accessible);
    };
    return Category;
}());
exports.Category = Category;
