"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Lister = void 0;
var PageFetcher_1 = require("./PageFetcher");
var AsyncIterator_1 = require("./AsyncIterator");
var Lister = /** @class */ (function () {
    function Lister(url, client, type, pageCreatorFunction) {
        this.pageFetcher = new PageFetcher_1.PageFetcher(url, client, pageCreatorFunction);
        this.url = url;
        this.client = client;
        this.type = type;
    }
    Lister.prototype.all = function (queryParams) {
        if (queryParams === void 0) { queryParams = null; }
        return new AsyncIterator_1.AsyncIterator(this.url, this.client, this.type, Lister.serialize(queryParams) || {});
    };
    Lister.prototype.firstPage = function (queryParams, pageSize) {
        if (queryParams === void 0) { queryParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.pageFetcher.fetchAfter(null, Lister.serialize(queryParams), pageSize);
    };
    Lister.prototype.pageAfter = function (afterId, queryParams, pageSize) {
        if (queryParams === void 0) { queryParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.pageFetcher.fetchAfter(afterId, Lister.serialize(queryParams), pageSize);
    };
    Lister.prototype.pageBefore = function (beforeId, queryParams, pageSize) {
        if (queryParams === void 0) { queryParams = null; }
        if (pageSize === void 0) { pageSize = null; }
        return this.pageFetcher.fetchBefore(beforeId, Lister.serialize(queryParams), pageSize);
    };
    Lister.serialize = function (queryParams) {
        // @ts-ignore
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.serialize) {
            // @ts-ignore
            return queryParams.serialize();
        }
        return queryParams;
    };
    return Lister;
}());
exports.Lister = Lister;
