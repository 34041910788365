"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Account = void 0;
var AccountSettings_1 = require("./AccountSettings");
var Account = /** @class */ (function () {
    function Account(json) {
        this.secretKey = json.secretKey;
        this.designerKey = json.designerKey;
        this.publicKey = json.publicKey;
        this.settings = new AccountSettings_1.AccountSettings(json.settings);
        this.company = json.company;
        this.email = json.email;
        this.role = json.role;
    }
    return Account;
}());
exports.Account = Account;
