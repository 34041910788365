export const ports = {
    subscribe: (app, portName, callback) => {
        if (app.ports[portName]) {
            app.ports[portName].subscribe(callback);
        } else {
            console.error('Trying to use missing port! [subscribe]', portName);
        }
    },

    send: (app, portName, args) => {
        if (app.ports[portName]) {
            app.ports[portName].send(args);
        } else {
            console.error('Trying to use missing port! [send]', portName);
        }
    },
};
