"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBookingConfig = void 0;
var TableBookingConfig = /** @class */ (function () {
    function TableBookingConfig(mode, tables) {
        this.mode = mode;
        this.tables = tables;
    }
    TableBookingConfig.inherit = function () {
        return new TableBookingConfig('INHERIT');
    };
    TableBookingConfig.allByTable = function () {
        return new TableBookingConfig('ALL_BY_TABLE');
    };
    TableBookingConfig.allBySeat = function () {
        return new TableBookingConfig('ALL_BY_SEAT');
    };
    TableBookingConfig.custom = function (tables) {
        return new TableBookingConfig('CUSTOM', tables);
    };
    return TableBookingConfig;
}());
exports.TableBookingConfig = TableBookingConfig;
