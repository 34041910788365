"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HelperFunctions = void 0;
var Labels_1 = require("../Common/Labels");
var LabelAndType_1 = require("../Common/LabelAndType");
var HelperFunctions = /** @class */ (function () {
    function HelperFunctions() {
    }
    HelperFunctions.labelCreator = function (json) {
        var labels;
        if (json.labels.parent) {
            labels = new Labels_1.Labels(new LabelAndType_1.LabelAndType(json.labels.own.label, json.labels.own.type), new LabelAndType_1.LabelAndType(json.labels.parent.label, json.labels.parent.type));
        }
        else {
            labels = new Labels_1.Labels(new LabelAndType_1.LabelAndType(json.labels.own.label, json.labels.own.type));
        }
        if (json.labels.section) {
            labels.setSection(json.labels.section);
        }
        return labels;
    };
    return HelperFunctions;
}());
exports.HelperFunctions = HelperFunctions;
