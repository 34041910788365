import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { getWebAutoInstrumentations } from '@opentelemetry/auto-instrumentations-web';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { ZoneContextManager } from '@opentelemetry/context-zone';
const { Resource } = require('@opentelemetry/resources');
const { SemanticResourceAttributes } = require('@opentelemetry/semantic-conventions');

const exporter = new OTLPTraceExporter({
    url: process.env.OTEL_COLLECTOR_URL,
});
const provider = new WebTracerProvider({
    resource: new Resource({
        [SemanticResourceAttributes.SERVICE_NAME]: 'backoffice-browser-' + process.env.VERCEL_ENV,
        [SemanticResourceAttributes.DEPLOYMENT_ENVIRONMENT]: process.env.VERCEL_ENV,
        [SemanticResourceAttributes.SERVICE_VERSION]: process.env.VERCEL_GIT_COMMIT_SHA,
        'git.commit-message': process.env.VERCEL_GIT_COMMIT_MESSAGE,
        'git.branch': process.env.VERCEL_GIT_COMMIT_REF,
    }),
});
class OurSpanProcessor extends BatchSpanProcessor {
    onStart(span, parentContext) {
        if (this._globalAttributes) {
            span.setAttributes(this._globalAttributes);
        }
        super.onStart(span, parentContext);
    }
    setGlobalAttributes(newGlobalAttributes) {
        this._globalAttributes = this._globalAttributes || {};
        Object.assign(this._globalAttributes, newGlobalAttributes);
    }
}

const spanProcessor = new OurSpanProcessor(exporter);
provider.addSpanProcessor(spanProcessor);
provider.register({
    contextManager: new ZoneContextManager(),
});

registerInstrumentations({
    instrumentations: [
        getWebAutoInstrumentations({
            // load custom configuration for xml-http-request instrumentation
            '@opentelemetry/instrumentation-xml-http-request': {
                propagateTraceHeaderCorsUrls: /.*/,
                ignoreUrls: [/seatsio\.net/],
            },
        }),
    ],
});
export { spanProcessor };
