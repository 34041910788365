"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChartValidationSettings = void 0;
var ChartValidationSettings = /** @class */ (function () {
    function ChartValidationSettings(json) {
        this.VALIDATE_DUPLICATE_LABELS = json.VALIDATE_DUPLICATE_LABELS;
        this.VALIDATE_OBJECTS_WITHOUT_CATEGORIES = json.VALIDATE_OBJECTS_WITHOUT_CATEGORIES;
        this.VALIDATE_UNLABELED_OBJECTS = json.VALIDATE_UNLABELED_OBJECTS;
        this.VALIDATE_FOCAL_POINT = json.VALIDATE_FOCAL_POINT;
        this.VALIDATE_OBJECT_TYPES_PER_CATEGORY = json.VALIDATE_OBJECT_TYPES_PER_CATEGORY;
        this.VALIDATE_EMPTY_FLOOR = json.VALIDATE_EMPTY_FLOOR;
    }
    return ChartValidationSettings;
}());
exports.ChartValidationSettings = ChartValidationSettings;
