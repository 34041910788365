class SeatsIOEventChart extends HTMLElement {
    connectedCallback() {
        const workspaceKey = this.getAttribute('data-workspace-key');
        const eventKey = this.getAttribute('data-event-key');
        console.log(workspaceKey, eventKey);
        new window.seatsio.SeatingChart({
            container: this,
            workspaceKey: workspaceKey,
            event: eventKey,
            mode: 'static',
            showFullScreenButton: false,
        }).render();
    }
}

const tagName = 'simple-seat-chart';

if (!customElements.get(tagName)) {
    customElements.define(tagName, SeatsIOEventChart);
}
