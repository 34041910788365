import '@webcomponents/custom-elements';

export class SeatsIOSeatChartDesigner extends HTMLElement {
    private chart: any;
    secretKey: string;
    chartKey: string;

    constructor() {
        super();
    }

    connectedCallback() {
        this.render();
    }

    render() {
        this.chart = new window.seatsio.SeatingChartDesigner({
            container: this,
            secretKey: this.secretKey,
            chartKey: this.chartKey,
            onChartCreated: (chartKey) => {
                const evt = new CustomEvent('chart-created', {
                    bubbles: false,
                    detail: { chartKey: chartKey },
                });
                this.dispatchEvent(evt);
            },
        }).render();
    }
}

customElements.define('seatsio-seatchart-designer', SeatsIOSeatChartDesigner);
