"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Invitations = void 0;
var Invitation_1 = require("./Invitation");
var Invitations = /** @class */ (function () {
    function Invitations(client) {
        this.client = client;
    }
    Invitations.prototype.listAll = function () {
        return this.client.get('/invitations')
            .then(function (res) { return res.data.map(function (json) { return new Invitation_1.Invitation(json); }); });
    };
    return Invitations;
}());
exports.Invitations = Invitations;
