"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Invitation = void 0;
var Invitation = /** @class */ (function () {
    function Invitation(json) {
        this.id = json.id;
        this.status = json.status;
        this.email = json.email;
        this.date = new Date(json.date);
    }
    return Invitation;
}());
exports.Invitation = Invitation;
