"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IDs = void 0;
var IDs = /** @class */ (function () {
    function IDs(own, parent, section) {
        if (parent === void 0) { parent = null; }
        if (section === void 0) { section = null; }
        this.own = own;
        this.parent = parent;
        this.section = section;
    }
    return IDs;
}());
exports.IDs = IDs;
