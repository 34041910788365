"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeasonParams = void 0;
var SeasonParams = /** @class */ (function () {
    function SeasonParams() {
    }
    SeasonParams.prototype.key = function (key) {
        this._key = key;
        return this;
    };
    SeasonParams.prototype.numberOfEvents = function (numberOfEvents) {
        this._numberOfEvents = numberOfEvents;
        return this;
    };
    SeasonParams.prototype.eventKeys = function (eventKeys) {
        this._eventKeys = eventKeys;
        return this;
    };
    SeasonParams.prototype.tableBookingConfig = function (tableBookingConfig) {
        this._tableBookingConfig = tableBookingConfig;
        return this;
    };
    SeasonParams.prototype.channels = function (channels) {
        this._channels = channels;
        return this;
    };
    SeasonParams.prototype.forSaleConfig = function (forSaleConfig) {
        this._forSaleConfig = forSaleConfig;
        return this;
    };
    return SeasonParams;
}());
exports.SeasonParams = SeasonParams;
