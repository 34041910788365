"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusChangeRequest = void 0;
var StatusChangeRequest = /** @class */ (function () {
    function StatusChangeRequest(eventKey, objectOrObjects, status, holdToken, orderId, keepExtraData, ignoreChannels, channelKeys, allowedPreviousStatuses, rejectedPreviousStatuses) {
        this.eventKey = eventKey;
        this.objectOrObjects = objectOrObjects;
        this.status = status;
        this.holdToken = holdToken;
        this.orderId = orderId;
        this.keepExtraData = keepExtraData;
        this.ignoreChannels = ignoreChannels;
        this.channelKeys = channelKeys;
        this.allowedPreviousStatuses = allowedPreviousStatuses;
        this.rejectedPreviousStatuses = rejectedPreviousStatuses;
    }
    return StatusChangeRequest;
}());
exports.StatusChangeRequest = StatusChangeRequest;
