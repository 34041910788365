export default function UnleashChecker(app, unleash) {
    let isReady = false;
    let isStarting = false;
    let toCheck = [];

    const that = {
        setContextIfNotReady: (context) => {
            if (!isReady && !isStarting) {
                console.log('setting unleash context');
                unleash.setContextField('userId', context.userId);
                unleash.setContextField('tenantHash', context.tenantHash);
                unleash.start();
                isStarting = true;
            }
        },
        setReady: () => {
            isReady = true;
            isStarting = false;
            toCheck.map(that.check);
            toCheck = [];
        },
        check: (feature) => {
            if (isReady) {
                app.ports.receiveToggleStatePort.send([feature, unleash.isEnabled(feature)]);
            } else {
                toCheck.push(feature);
            }
        },
        isReady: () => isReady,
    };

    return that;
}
