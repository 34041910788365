"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusChangeOrigin = void 0;
var StatusChangeOrigin = /** @class */ (function () {
    function StatusChangeOrigin(json) {
        this.type = json.type;
        this.ip = json.ip;
    }
    return StatusChangeOrigin;
}());
exports.StatusChangeOrigin = StatusChangeOrigin;
