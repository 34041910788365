"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DefaultRendererSettings = void 0;
var DefaultRendererSettings = /** @class */ (function () {
    function DefaultRendererSettings(json) {
        this.showFullScreenButton = json.showFullScreenButton;
        this.multiSelectEnabled = json.multiSelectEnabled;
    }
    return DefaultRendererSettings;
}());
exports.DefaultRendererSettings = DefaultRendererSettings;
