/**
 * Simple detection if OS is mac or not.
 * Based on that it keep different content
 */
class IsItMacElement extends HTMLElement {
    connectedCallback() {
        const macContent = this.querySelector('for-mac');
        const restContent = this.querySelector('for-rest');
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;

        if (isMac) {
            restContent.style.display = 'none';
        } else {
            macContent.style.display = 'none';
        }
    }
}

if (!customElements.get('is-it-mac')) {
    customElements.define('is-it-mac', IsItMacElement);
}
