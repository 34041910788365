import seatsIoEventSummary from '../../shared/seatsio/eventSummary';

const tracing = require('./tracing');
import { Elm } from './Backoffice.elm';
import * as serviceWorker from './serviceWorker';
import 'elm-keyboard-shortcut';
import { UnleashClient } from 'unleash-proxy-client';
import dxBuilders from '../public/static/DX.jpg';
import maintenanceBackground from '../public/static/maintenance.png';
import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';
import ls from 'localstorage-slim';
import UnleashChecker from './_lib/unleash-checker';
import './_lib/is-it-mac';
import './_lib/seatsIOSeatChartDesigner.ts';
import './_lib/SeatsIOEventChart.js';
import { SeatsioClient, Region } from 'seatsio';
import { CaptureConsole } from '@sentry/integrations';
import { ports } from '../../shared/utils/ports.ts';

const STORAGE_KEY_LAST_USED_TENANT_ID = 'STORAGE_KEY_LAST_USED_TENANT_ID';
const LOCAL_STORAGE_KEY = 'LOCAL_STORAGE_KEY';

const app = Elm.Backoffice.init({
    node: document.getElementById('root'),
    flags: {
        static: { dxBuilders, maintenanceBackground },
        now: new Date().valueOf(),
        lastUsedTenantId: localStorage.getItem(STORAGE_KEY_LAST_USED_TENANT_ID),
    },
});

app.ports.storeLastUsedTenantId.subscribe((tenantId) => {
    localStorage.setItem(STORAGE_KEY_LAST_USED_TENANT_ID, tenantId);
});

app.ports.localStorageSetValue &&
    app.ports.localStorageSetValue.subscribe((value) => {
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(value));
    });

app.ports.localStorageGetValues.subscribe(() => {
    app.ports.localStorageGotValues.send(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)));
});

const environments = { prod: 'production', stage: 'staging', dev: 'development' };
// unleash
const unleashEnv = (env) => {
    if (env === 'production') {
        return environments.prod;
    }
    if (env === 'preview') {
        return environments.stage;
    }
    return environments.dev;
};

const unleash = new UnleashClient({
    url: process.env.UNLEASH_URL,
    clientKey: process.env.UNLEASH_CLIENT_KEY,
    appName: 'backoffice', // hardcoded for now
    environment: unleashEnv(process.env.VERCEL_ENV),
});

const unleashChecker = UnleashChecker(app, unleash);

unleash.on('ready', () => {
    unleashChecker.setReady();
});

unleash.on('update', () => {
    if (process.env.VERCEL_ENV !== 'development') {
        app.ports.processUpdatedFeatures.send(null);
    } else {
        console.log('Received feature toggles');
    }
});

ports.subscribe(app, 'isEnabledPort', unleashChecker.check);

app.ports.isEnabledForUserPort.subscribe(({ userId, feature, tenantHash }) => {
    unleashChecker.setContextIfNotReady({ userId: userId, tenantHash: tenantHash });
    unleashChecker.check(feature);
});

app.ports.setContextForFeatureToggles.subscribe(({ userId, tenantHash }) => {
    console.log('set new tenant for feature toggles');

    unleash.setContextField('userId', userId);
    unleash.setContextField('tenantHash', tenantHash);
});

// end unleash feature toggles

// ErrorReporting

if (process.env.VERCEL_ENV !== 'development') {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        integrations: [new BrowserTracing(), new CaptureConsole({ levels: ['error'] })],
        release: process.env.VERCEL_GIT_COMMIT_SHA,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.VERCEL_ENV,
    });
}

// Check if this exists because it is not being used right now. Remove when we start using it
app.ports.transferErrorReport &&
    app.ports.transferErrorReport.subscribe((errorReport) => {
        if (process.env.VERCEL_ENV === 'development') {
            // If error is in development, just log it to console instead of
            // flooding sentry with it.
            console.log('------------------  NO SENTRY IN DEV ENVIRONMENT ------------------');
            console.error(errorReport);
            console.log('-------------------------------------------------------------------');
        } else {
            let user = ls.get('CURRENT_USER', { decrypt: true });

            Sentry.withScope(function (scope) {
                scope.setLevel(errorReport.errorType);

                scope.setTag('Filename', errorReport.file);
                scope.setTag('Function', errorReport.function);

                if (user != null) {
                    scope.setUser({ email: user.email });
                    scope.setTag('TenantId', user.tenantId);
                }

                // The exception has the event level set by the scope (info).
                let err = new Error();
                err.name = errorReport.title;
                err.message = errorReport.message;

                Sentry.captureException(err);
            });
        }
    });

// End ErrorReporting

// Tracking

app.ports.setUserForTracking.subscribe((loggedInUser) => {
    // Set user so other functions can fetch userdetails
    ls.set('CURRENT_USER', loggedInUser, { encrypt: true });

    if (process.env.VERCEL_ENV !== 'development') {
        // pendo tracking
        pendo.initialize({
            visitor: {
                id: loggedInUser.email,
                email: loggedInUser.email,
                full_name: loggedInUser.firstName + ' ' + loggedInUser.lastName,
            },
            account: {
                id: loggedInUser.tenantId,
            },
        });
    }
    tracing.spanProcessor.setGlobalAttributes({
        'enduser.id': loggedInUser.id,
        'enduser.email': loggedInUser.email,
        'enduser.tenant.id': loggedInUser.tenantId,
    });
});

// End Tracking

// Clipboard functionality
ports.subscribe(app, 'createSeatsIOEvent', (seatsioEvent) => {
    if (seatsioEvent.workspaceSecret !== null) {
        let client = new SeatsioClient(Region.EU(), seatsioEvent.workspaceSecret);

        client.events
            .create(seatsioEvent.chartKey)
            .then((obj) => {
                app.ports.seatsIOEventCreated.send({ screeningId: seatsioEvent.screeningId, eventKey: obj.key });
            })
            .catch(console.error);
    }
});
// End Clipboard

app.ports.createEvent.subscribe((seatsIOEvent) => {
    if (seatsIOEvent.workspaceSecret !== null) {
        let client = new SeatsioClient(Region.EU(), seatsIOEvent.workspaceSecret);

        client.events
            .create(seatsIOEvent.chartKey, {
                name: seatsIOEvent.title,
                date: seatsIOEvent.date,
            })
            .then((obj) => {
                app.ports.eventCreated.send({ eventId: seatsIOEvent.eventId, eventKey: obj.key });
            })
            .catch((err) => {
                console.error(err);
                app.ports.eventCreated.send({ eventId: seatsIOEvent.eventId, errors: err.errors });
            });
    } else {
        app.ports.eventCreated.send({
            eventId: seatsIOEvent.eventId,
            errors: [{ code: 'SEATSIO_WORKSPACEKEY_MISSING', message: 'SeatsIO workspacekey is missing' }],
        });
    }
});

app.ports.fetchSeatsIOEventReport &&
    app.ports.eventReportReceived &&
    seatsIoEventSummary.setup(app.ports.fetchSeatsIOEventReport.subscribe, app.ports.eventReportReceived.send);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
