"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Workspaces = void 0;
var Workspace_1 = require("./Workspace");
var Lister_1 = require("../Lister");
var Page_1 = require("../Page");
var Workspaces = /** @class */ (function () {
    function Workspaces(client) {
        this.client = client;
    }
    Workspaces.prototype.create = function (name, isTest) {
        if (isTest === void 0) { isTest = false; }
        var requestParameters = { name: name, isTest: isTest };
        return this.client.post('/workspaces', requestParameters)
            .then(function (res) { return new Workspace_1.Workspace(res.data); });
    };
    Workspaces.prototype.update = function (key, name) {
        var requestParameters = { name: name };
        return this.client.post("/workspaces/".concat(key), requestParameters);
    };
    Workspaces.prototype.setDefault = function (key) {
        var requestParameters = { key: key };
        return this.client.post("/workspaces/actions/set-default/".concat(key), requestParameters);
    };
    Workspaces.prototype.regenerateSecretKey = function (key) {
        return this.client.post("/workspaces/".concat(key, "/actions/regenerate-secret-key"))
            .then(function (res) { return res.data.secretKey; });
    };
    Workspaces.prototype.activate = function (key) {
        return this.client.post("/workspaces/".concat(key, "/actions/activate"));
    };
    Workspaces.prototype.deactivate = function (key) {
        return this.client.post("/workspaces/".concat(key, "/actions/deactivate"));
    };
    Workspaces.prototype.retrieve = function (key) {
        return this.client.get("/workspaces/".concat(key)).then(function (res) { return new Workspace_1.Workspace(res.data); });
    };
    Workspaces.prototype.listAll = function (filter) {
        if (filter === void 0) { filter = null; }
        var requestParameters = filter !== null ? { filter: filter } : {};
        return this.iterator().all(requestParameters);
    };
    Workspaces.prototype.listFirstPage = function (filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.iterator().firstPage(requestParameters, pageSize);
    };
    Workspaces.prototype.listPageAfter = function (afterId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.iterator().pageAfter(afterId, requestParameters, pageSize);
    };
    Workspaces.prototype.listPageBefore = function (beforeId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.iterator().pageBefore(beforeId, requestParameters, pageSize);
    };
    Workspaces.prototype.listActive = function (filter) {
        if (filter === void 0) { filter = null; }
        var requestParameters = filter !== null ? { filter: filter } : {};
        return this.activeWorkspacesIterator().all(requestParameters);
    };
    Workspaces.prototype.listActiveFirstPage = function (filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.activeWorkspacesIterator().firstPage(requestParameters, pageSize);
    };
    Workspaces.prototype.listActivePageAfter = function (afterId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.activeWorkspacesIterator().pageAfter(afterId, requestParameters, pageSize);
    };
    Workspaces.prototype.listActivePageBefore = function (beforeId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.activeWorkspacesIterator().pageBefore(beforeId, requestParameters, pageSize);
    };
    Workspaces.prototype.listInactive = function (filter) {
        if (filter === void 0) { filter = null; }
        var requestParameters = filter !== null ? { filter: filter } : {};
        return this.inactiveWorkspacesIterator().all(requestParameters);
    };
    Workspaces.prototype.listInactiveFirstPage = function (filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.inactiveWorkspacesIterator().firstPage(requestParameters, pageSize);
    };
    Workspaces.prototype.listInactivePageAfter = function (afterId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.inactiveWorkspacesIterator().pageAfter(afterId, requestParameters, pageSize);
    };
    Workspaces.prototype.listInactivePageBefore = function (beforeId, filter, pageSize) {
        if (filter === void 0) { filter = null; }
        if (pageSize === void 0) { pageSize = null; }
        var requestParameters = filter !== null ? { filter: filter } : null;
        return this.inactiveWorkspacesIterator().pageBefore(beforeId, requestParameters, pageSize);
    };
    Workspaces.prototype.iterator = function () {
        return new Lister_1.Lister('/workspaces', this.client, 'workspaces', function (data) {
            var workspaces = data.items.map(function (json) { return new Workspace_1.Workspace(json); });
            return new Page_1.Page(workspaces, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    Workspaces.prototype.activeWorkspacesIterator = function () {
        return new Lister_1.Lister('/workspaces/active', this.client, 'workspaces', function (data) {
            var workspaces = data.items.map(function (json) { return new Workspace_1.Workspace(json); });
            return new Page_1.Page(workspaces, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    Workspaces.prototype.inactiveWorkspacesIterator = function () {
        return new Lister_1.Lister('/workspaces/inactive', this.client, 'workspaces', function (data) {
            var workspaces = data.items.map(function (json) { return new Workspace_1.Workspace(json); });
            return new Page_1.Page(workspaces, data.next_page_starts_after, data.previous_page_ends_before);
        });
    };
    return Workspaces;
}());
exports.Workspaces = Workspaces;
