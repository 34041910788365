"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsageReports = void 0;
var UsageReports = /** @class */ (function () {
    function UsageReports(client) {
        this.client = client;
    }
    UsageReports.prototype.summaryForAllMonths = function () {
        return this.client.get('/reports/usage?version=2')
            .then(function (res) { return ({
            usageCutoffDate: new Date(res.data.usageCutoffDate),
            usage: res.data.usage
        }); });
    };
    UsageReports.prototype.detailsForMonth = function (month) {
        return this.client.get("/reports/usage/month/".concat(month))
            .then(function (res) { return res.data; });
    };
    UsageReports.prototype.detailsForEventInMonth = function (eventId, month) {
        return this.client.get("/reports/usage/month/".concat(month, "/event/").concat(eventId))
            .then(function (res) { return res.data; });
    };
    UsageReports.prototype.subscription = function () {
        return this.client.get('/reports/subscription')
            .then(function (res) { return res.data; });
    };
    return UsageReports;
}());
exports.UsageReports = UsageReports;
