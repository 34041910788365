"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Channel = void 0;
var Channel = /** @class */ (function () {
    function Channel(json) {
        this.key = json.key;
        this.name = json.name;
        this.color = json.color;
        this.index = json.index;
        this.objects = json.objects;
    }
    return Channel;
}());
exports.Channel = Channel;
