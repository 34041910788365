"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalDate = void 0;
var LocalDate = /** @class */ (function () {
    function LocalDate(year, month, day) {
        this.year = year;
        this.month = month;
        this.day = day;
    }
    LocalDate.prototype.toString = function () {
        return this.year + '-' + String(this.month).padStart(2, '0') + '-' + String(this.day).padStart(2, '0');
    };
    LocalDate.parse = function (date) {
        var year = parseInt(date.substring(0, 4));
        var month = parseInt(date.substring(5, 7));
        var day = parseInt(date.substring(8, 10));
        return new LocalDate(year, month, day);
    };
    return LocalDate;
}());
exports.LocalDate = LocalDate;
