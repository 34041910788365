"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Accounts = void 0;
var Account_1 = require("./Account");
var baseUrl = '/accounts/me';
var Accounts = /** @class */ (function () {
    function Accounts(client) {
        this.client = client;
    }
    Accounts.prototype.retrieveMyAccount = function () {
        return this.client.get(baseUrl).then(function (res) { return new Account_1.Account(res.data); });
    };
    Accounts.prototype.regenerateSecretKey = function () {
        return this.client.post(baseUrl + '/secret-key/actions/regenerate').then(function (res) { return res.data.secretKey; });
    };
    Accounts.prototype.regenerateDesignerKey = function () {
        return this.client.post(baseUrl + '/designer-key/actions/regenerate').then(function (res) { return res.data.designerKey; });
    };
    Accounts.prototype.enableDraftChartDrawings = function () {
        return this.client.post(baseUrl + '/draft-chart-drawings/actions/enable');
    };
    Accounts.prototype.disableDraftChartDrawings = function () {
        return this.client.post(baseUrl + '/draft-chart-drawings/actions/disable');
    };
    Accounts.prototype.changePassword = function (password) {
        return this.client.post(baseUrl + '/actions/change-password', { password: password });
    };
    Accounts.prototype.changeHoldPeriod = function (holdPeriodInMinutes) {
        return this.client.post(baseUrl + '/actions/change-hold-period', { holdPeriodInMinutes: holdPeriodInMinutes });
    };
    Accounts.prototype.updateSetting = function (key, value) {
        return this.client.post(baseUrl + '/settings', { key: key, value: value });
    };
    return Accounts;
}());
exports.Accounts = Accounts;
