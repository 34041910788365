"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusChange = void 0;
var StatusChange = /** @class */ (function () {
    function StatusChange(json) {
        this.id = json.id;
        this.eventId = json.eventId;
        this.status = json.status;
        this.quantity = json.quantity;
        this.objectLabel = json.objectLabel;
        this.date = new Date(json.date);
        this.orderId = json.orderId ? json.orderId : null;
        this.extraData = json.extraData ? json.extraData : null;
        this.holdToken = json.holdToken ? json.holdToken : null;
        this.origin = json.origin;
        this.isPresentOnChart = json.isPresentOnChart;
        this.notPresentOnChartReason = json.notPresentOnChartReason;
        this.displayedLabel = json.displayedLabel ? json.displayedLabel : null;
    }
    return StatusChange;
}());
exports.StatusChange = StatusChange;
