"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Utilities = void 0;
var ChartObjectInfo_1 = require("../Charts/ChartObjectInfo");
var EventObjectInfo_1 = require("../Events/EventObjectInfo");
var Utilities = /** @class */ (function () {
    function Utilities() {
    }
    Utilities.createChangeObjectStatusDetails = function (data) {
        var objectDetails = {};
        for (var key in data) {
            objectDetails[key] = new EventObjectInfo_1.EventObjectInfo(data[key]);
        }
        return objectDetails;
    };
    Utilities.createEventReport = function (reportsData) {
        var reportObjects = {};
        for (var _i = 0, _a = Object.keys(reportsData); _i < _a.length; _i++) {
            var key = _a[_i];
            reportObjects[key] = reportsData[key].map(function (data) { return new EventObjectInfo_1.EventObjectInfo(data); });
        }
        return reportObjects;
    };
    Utilities.createChartReport = function (reportsData) {
        var reportObjects = {};
        for (var _i = 0, _a = Object.keys(reportsData); _i < _a.length; _i++) {
            var key = _a[_i];
            reportObjects[key] = reportsData[key].map(function (data) { return new ChartObjectInfo_1.ChartObjectInfo(data); });
        }
        return reportObjects;
    };
    return Utilities;
}());
exports.Utilities = Utilities;
